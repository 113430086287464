import request from '@/utils/request'


// 查询研学活动列表
export function listStudy(query) {
  return request({
    url: '/study/study/list',
    method: 'get',
    params: query
  })
}

// 查询研学活动分页
export function pageStudy(query) {
  return request({
    url: '/study/study/page',
    method: 'get',
    params: query
  })
}

// 查询研学活动详细
export function getStudy(data) {
  return request({
    url: '/study/study/detail',
    method: 'get',
    params: data
  })
}

// 查询研学活动详细
export function isStudy() {
  return request({
    url: '/study/study/isStudy',
    method: 'get',
  })
}

// 新增研学活动
export function addStudy(data) {
  return request({
    url: '/study/study/add',
    method: 'post',
    data: data
  })
}

// 修改研学活动
export function updateStudy(data) {
  return request({
    url: '/study/study/edit',
    method: 'post',
    data: data
  })
}

// 删除研学活动
export function delStudy(data) {
  return request({
    url: '/study/study/delete',
    method: 'post',
    data: data
  })
}
